import React from "react";
import { inject, observer } from "mobx-react";
import { ApplicationStore } from "../../store/applicationStore";
import InputMask from "react-input-mask";
import { observable } from "mobx";
import { AppointmentTypeModel } from "../../models/appointmentTypeModel";
import moment from "moment";

interface IProps {}

interface IInjectProps {
  applicationStore: ApplicationStore;
}

@inject("applicationStore")
@observer
export class AppointmentComponent extends React.Component<IProps> {
  get injected() {
    return (this.props as unknown) as IInjectProps;
  }

  inputFIO: HTMLInputElement;
  @observable ddShown: boolean;
  @observable appointmentType: AppointmentTypeModel;
  @observable comment: string;

  constructor(props: IProps) {
    super(props);

    this.ddShown = false;
    this.comment = "";

    // If there's only one type of appointment - choose it right away
    this.injected.applicationStore.getAppointmentTypes().then(() => {
      if (this.injected.applicationStore.appointmentTypes.length === 1) {
        this.setType(this.injected.applicationStore.appointmentTypes[0]);
      }
    });

    this.appointmentType = new AppointmentTypeModel();
    document.addEventListener("click", this.ddHide.bind(this), true);
  }

  componentDidMount() {
    this.inputFIO.focus();
  }

  componentWillUnmount() {
    document.removeEventListener("click", this.ddHide.bind(this), true);
  }

  ddHide() {
    if (this.ddShown) this.ddShown = false;
  }

  setType = (appointmentType: AppointmentTypeModel) => {
    this.ddShown = false;
    this.appointmentType = appointmentType;
    this.injected.applicationStore.total.details.appointmentType = appointmentType;
    this.comment = appointmentType.comment;
  };

  render() {
    return (
      <React.Fragment>
        <div className="form">
          <div className="form__title">Укажите свои данные</div>
          <div className="form__row">
            <input
              ref={(input) => {
                this.inputFIO = input;
              }}
              className="form__control"
              type="text"
              id="fullName"
              placeholder="Иванов Иван Петрович"
              onChange={(e) =>
                (this.injected.applicationStore.total.details.fullname = (e.target as HTMLInputElement).value)
              }
              value={this.injected.applicationStore.total.details.fullname}
            />
            <label className="form__label" htmlFor="fullName">
              ФИО
            </label>
          </div>
          <div className="form__row">
            <InputMask
              mask="+7 (999) 999-99-99"
              className="form__control"
              id="phone"
              placeholder="+7 (999) 999-99-99"
              onChange={(e) =>
                (this.injected.applicationStore.total.details.phone = (e.target as HTMLInputElement).value)
              }
              value={this.injected.applicationStore.total.details.phone}
            />
            <label className="form__label" htmlFor="phone">
              Телефон
            </label>
          </div>
          <div className="form__row">
            <input
              className="form__control"
              type="email"
              id="email"
              placeholder="Email"
              onChange={(e) =>
                (this.injected.applicationStore.total.details.email = (e.target as HTMLInputElement).value)
              }
              value={this.injected.applicationStore.total.details.email}
            />
            <label className="form__label" htmlFor="phone">
              Email
            </label>
          </div>
          {this.injected.applicationStore.appointmentTypes.length > 1 && (
            <div className="form__row">
              <div className="dd">
                <div
                  className="dd__value"
                  onClick={() => (this.ddShown = !this.ddShown)}
                >
                  {this.appointmentType.name || "Тип приема"}
                </div>
                <div
                  className={this.ddShown ? "dd__list is-shown" : "dd__list"}
                >
                  {this.injected.applicationStore.appointmentTypes.map(
                    (option) => (
                      <div
                        className={
                          this.appointmentType == option
                            ? "dd__option is-active"
                            : "dd__option"
                        }
                        key={option.id}
                        onClick={() => this.setType(option)}
                      >
                        {option.name}
                      </div>
                    )
                  )}
                </div>
              </div>
              <label className="form__label is-active">Тип приема</label>
              {this.comment && (
                <div className="form__comment">{this.comment}</div>
              )}
            </div>
          )}
          <div className="form__row">
            <textarea
              className="form__control"
              id="comment"
              rows={5}
              placeholder="Консультация.&#10;Прошу перезвонить для уточнения."
              onInput={(e) =>
                (this.injected.applicationStore.total.details.comment = (e.target as HTMLTextAreaElement).value)
              }
            />
            <label className="form__label" htmlFor="comment">
              Комментарий
            </label>
          </div>
        </div>
        <div className="form__doctor">
          <div className="doctor">
            <div className="doctor__photo">
              {this.injected.applicationStore.total.doctor.photoUrl && (
                <img
                  src={this.injected.applicationStore.total.doctor.photoUrl}
                />
              )}
            </div>
            <div className="doctor__name">
              {this.injected.applicationStore.total.doctor.userName}
            </div>
            <div className="doctor__specializations">
              {this.injected.applicationStore.total.doctor.specializations}
            </div>
            <div className="doctor__description">
              {this.injected.applicationStore.total.doctor.description}
            </div>
          </div>
        </div>
        <div className="details">
          <div className="details__row details__row--main">
            <div className="details__label">Услуга</div>
            <div className="details__data">
              {this.injected.applicationStore.total.service.name}
            </div>
          </div>
          <div className="details__row">
            <div className="details__label">Дата</div>
            <div className="details__data details__data--nowrap">
              {moment(
                this.injected.applicationStore.total.datetime.date
              ).format("DD-MM-YYYY")}
            </div>
          </div>
          <div className="details__row">
            <div className="details__label">Время</div>
            <div className="details__data details__data--nowrap">
              {moment(
                this.injected.applicationStore.total.datetime.timeBegin,
                "HH:mm:ss"
              ).format("HH:mm")}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

import React from "react";
import { ApplicationStore } from "../../store/applicationStore";
import { inject, observer } from "mobx-react";
import moment from "moment";

interface IProps {}

interface IInjectProps {
  applicationStore: ApplicationStore;
}

@inject("applicationStore")
@observer
export class SuccessComponent extends React.Component<IProps> {
  get injected() {
    return (this.props as unknown) as IInjectProps;
  }
  constructor(props: IProps) {
    super(props);
  }

  render() {
    return (
      <React.Fragment>
        <div className="result">Вы успешно записались на прием</div>
        <div className="details">
          <div className="details__row">
            <div className="details__label">Специалист</div>
            <div className="details__data">
              {this.injected.applicationStore.total.doctor.userName} —{" "}
              {this.injected.applicationStore.total.doctor.specializations}
            </div>
          </div>
          <div className="details__row">
            <div className="details__label">Дата</div>
            <div className="details__data">
              {moment(
                this.injected.applicationStore.total.datetime.date
              ).format("DD-MM-YYYY")}
            </div>
          </div>
          <div className="details__row">
            <div className="details__label">Время</div>
            <div className="details__data">
              {moment(
                this.injected.applicationStore.total.datetime.timeBegin,
                "HH:mm:ss"
              ).format("HH:mm")}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { App } from './container/app/App';
import './index.scss';

// ReactDOM.render(
//   <App />,
//   document.getElementById('root') as HTMLElement
// );

ReactDOM.render(
  <App API={window["API"]} termsLink={window["termsLink"] || ""}/>,
  document.getElementById("root")
);
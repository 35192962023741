import { observable, computed, toJS, action, runInAction } from "mobx";
import { BranchModel } from "../models/branchModel";
import Networking from "../api/networking";
import { TotalModel } from "../models/totalModel";
import { SpecializationModel } from "../models/specializationModel";
import { DoctorModel } from "../models/doctorModel";
import moment from "moment";
import { DatetimeModel } from "../models/datetimeModel";
import { AppointmentModel } from "../models/appointmentModel";
import { AppointmentTypeModel } from "../models/appointmentTypeModel";
import { StageModel } from "../models/stageModel";
import { ServiceModel } from "../models/serviceModel";
import { WorkDay } from "../models/workDay";

export class ApplicationStore {
  @observable stage: number;
  @observable loading: boolean;
  @observable branches: Array<BranchModel>;
  @observable services: Array<ServiceModel>;
  @observable doctors: Array<DoctorModel>;
  @observable total: TotalModel;
  @observable specialization: Array<SpecializationModel>;
  @observable appointmentTypes: Array<AppointmentTypeModel>;
  @observable datetimes: Array<DatetimeModel>;
  @observable workDates: Array<string>;
  @observable error: string;
  @observable result: string;
  @observable stages: Array<StageModel>;
  @observable termsLink: string;
  networking: Networking;

  apiUrl: string;

  constructor(API: string, termsLink: string) {
    this.apiUrl = API;
    this.loading = false;
    this.stage = 1;
    this.branches = new Array<BranchModel>();
    this.services = new Array<ServiceModel>();
    this.total = new TotalModel();
    this.specialization = new Array<SpecializationModel>();
    this.doctors = new Array<DoctorModel>();
    this.appointmentTypes = new Array<AppointmentTypeModel>();
    this.datetimes = new Array<DatetimeModel>();
    this.workDates = new Array<string>();
    this.stages = new Array<StageModel>();
    this.error = "";
    this.result = "";
    this.networking = new Networking(API);
    this.termsLink = termsLink;
  }

  @computed get nextDisabled() {
    if (this.stage == 1) {
      if (this.total.branch.id) return false;
    } else if (this.stage == 2) {
      if (this.total.doctor.id) return false;
    } else if (this.stage == 3) {
      if (this.total.datetime) return false;
    } else if (this.stage == 4) {
      let stage1 = Boolean(this.total.branch.id);
      let stage2 = Boolean(this.total.doctor.id);
      let stage3 = Boolean(this.total.datetime);
      if (
        this.total.details.fullname != "" &&
        this.total.details.phone != "" &&
        this.total.details.phone != "+7 (__) ___-__-__" &&
        this.total.details.phone.indexOf("_") == -1 &&
        this.total.details.appointmentType.id &&
        stage1 &&
        stage2 &&
        stage3
      ) {
        return false;
      }
    }
    return true;
  }

  makeAppointment = () => {
    let data: AppointmentModel = {
      CommerceServiceId: this.total.service.id,
      LeadName: this.total.details.fullname,
      LeadPhone: this.total.details.phone,
      LeadEmail: this.total.details.email,
      Date: this.total.datetime.date,
      TimeBegin: this.total.datetime.timeBegin,
      TimeEnd: this.total.datetime.timeEnd,
      AppointmentTypeId: this.total.details.appointmentType.id,
      DoctorSpecializationId: this.total.doctor.id,
      ClinicBranchId: this.total.branch.id,
      Comment: this.total.details.comment,
    };
    if (this.total.datetime.cabinetId != null) {
      data.CabinetId = this.total.datetime.cabinetId;
    }

    this.networking.request<String>("POST", "Schedules", data).then((resp) => {
      setTimeout(() => {
        this.loading = false;
      }, 200);
      if (resp.success) {
        if (resp.statusCode == 200) {
          this.error =
            "На данное время запись невозможна. При повторении - записывайтесь в телефонном режиме.";
        } else if (resp.statusCode == 201) {
          this.stage = 5;
        }
      } else {
        this.error =
          "Ошибка записи на приём. При повторении - записывайтесь в телефонном режиме.";
      }
    });
  };

  initTotalDate = () => {
    this.total.datetime = new DatetimeModel();
    this.total.datetime.date = moment(new Date()).format();
  };

  getBranches = () => {
    this.loading = true;
    this.networking
      .request<Array<BranchModel>>("GET", "ClinicBranches", {})
      .then((resp) => {
        setTimeout(() => {
          this.loading = false;
        }, 200);
        if (resp.success) {
          this.branches = resp.payload;
          this.stages = new Array<StageModel>();
          if (this.branches.length == 1) {
            this.total.branch = this.branches[0];
            this.stage = 2;
          }
          if (this.branches.length > 1) {
            this.stages.push({
              name: "Филиал",
              counter: this.stages.length + 1,
              stage: 1,
            } as StageModel);
          }
          this.stages.push({
            name: "Услуга",
            counter: this.stages.length + 1,
            stage: 2,
          } as StageModel);
          this.stages.push({
            name: "Врач",
            counter: this.stages.length + 1,
            stage: 3,
          } as StageModel);
          // this.stages.push({
          //   name: "Дата и время",
          //   counter: this.stages.length + 1,
          //   stage: 4,
          // } as StageModel);
          this.stages.push({
            name: "Запись на прием",
            counter: this.stages.length + 1,
            stage: 4,
          } as StageModel);
        } else {
        }
      });
  };

  getSpecialization = () => {
    this.loading = true;
    this.networking
      .request<Array<SpecializationModel>>(
        "GET",
        "Specializations/" + this.total.branch.id,
        {}
      )
      .then((resp) => {
        setTimeout(() => {
          this.loading = false;
        }, 200);
        if (resp.success) {
          this.specialization = resp.payload;
        } else {
        }
      });
  };

  getDoctors = async (loadingSwitch: Boolean = true) => {
    if (loadingSwitch) {
      this.loading = true;
    }
    await this.networking
      .request<Array<DoctorModel>>(
        "GET",
        "Doctors/" + this.total.branch.id + "/" + this.total.service.id,
        {}
      )
      .then((resp) => {
        if (loadingSwitch) {
          setTimeout(() => {
            this.loading = false;
          }, 200);
        }
        if (resp.success) {
          return (this.doctors = resp.payload);
        } else {
        }
      });
  };

  getServices = () => {
    this.loading = true;
    return this.networking
      .request<Array<ServiceModel>>(
        "GET",
        "CommerceServices/" + this.total.branch.id,
        {}
      )
      .then((resp) => {
        setTimeout(() => {
          this.loading = false;
        }, 200);
        if (resp.success) {
          let serviceParents = resp.payload.filter(
            (el) => el.parentId === null
          );
          for (let service of serviceParents) {
            service.selected = false;
            this.getServiceChilds(resp.payload, service);
          }
          return (this.services = serviceParents);
        } else {
        }
      });
  };

  getServiceChilds = (services: Array<ServiceModel>, service: ServiceModel) => {
    var childs = services.filter((el) => el.parentId === service.id);
    service.childs = childs;
    for (let child of childs) {
      child.selected = false;
      this.getServiceChilds(services, child);
    }
  };

  getAppointmentTypes = () => {
    this.loading = true;
    return this.networking
      .request<Array<AppointmentTypeModel>>("GET", "AppointmentTypes", {})
      .then((resp) => {
        setTimeout(() => {
          this.loading = false;
        }, 200);
        if (resp.success) {
          this.appointmentTypes = resp.payload;
        } else {
        }
      });
  };

  getWorkDates = async (date: Date, loadingSwitch: Boolean = true) => {
    if (loadingSwitch) {
      this.loading = true;
    }
    let dateCompare = moment().diff(moment(date).startOf("month"), "days");
    let data = {
      FromDate:
        dateCompare > 0
          ? moment().format("YYYY-MM-DD")
          : moment(date).startOf("month").format("YYYY-MM-DD"),
      TillDate: moment(date).endOf("month").format("YYYY-MM-DD"),
      // FromDate: moment(date).format("YYYY-MM-DD"),
      // TillDate: moment(date).format("YYYY-MM-DD"),
      ClinicBranchId: this.total.branch.id,
      CommerceServiceId: this.total.service.id,
    };
    await this.networking
      .request<Array<string>>("GET", "Shifts", data)
      .then((resp) => {
        if (loadingSwitch) {
          setTimeout(() => {
            this.loading = false;
          }, 200);
        }
        if (resp.success) {
          this.workDates = resp.payload;
        } else {
        }
      });
  };

  getDateTime = async (date: Date, loadingSwitch: Boolean = true) => {
    if (loadingSwitch) {
      this.loading = true;
    }
    let data = {
      // FromDate: moment(date).startOf("month").format("YYYY-MM-DD"),
      // TillDate: moment(date).endOf("month").format("YYYY-MM-DD"),
      FromDate: moment(date).format("YYYY-MM-DD"),
      TillDate: moment(date).format("YYYY-MM-DD"),
      ClinicBranchId: this.total.branch.id,
      CommerceServiceId: this.total.service.id,
    };
    await this.networking
      .request<Array<DatetimeModel>>("GET", "DoctorsFreeTime", data)
      .then((resp) => {
        if (loadingSwitch) {
          setTimeout(() => {
            this.loading = false;
          }, 200);
        }
        if (resp.success) {
          this.datetimes = resp.payload;

          for (let dateCurrent of resp.payload) {
            for (let doctor of this.doctors) {
              if (
                dateCurrent.doctorSpecializationId === doctor.id &&
                moment(date).isSame(dateCurrent.date, "day")
              ) {
                if (!doctor.times) {
                  doctor.times = new Array<DatetimeModel>();
                }
                doctor.times.push(dateCurrent);
              }
            }
          }
        } else {
        }
      });
  };

  loadingSwitcher = () => {
    if (this.loading) {
      setTimeout(() => {
        this.loading = false;
      }, 200);
    } else {
      this.loading = true;
    }
  };
}

import { observable } from "mobx";
import { BranchModel } from "./branchModel";
import { DoctorModel } from "./doctorModel";
import { AppointmentTypeModel } from "./appointmentTypeModel";
import { DatetimeModel } from "./datetimeModel";
import { ServiceModel } from "./serviceModel";

export class TotalModel {
  @observable branch: BranchModel;
  @observable service: ServiceModel;
  @observable doctor: DoctorModel;
  @observable datetime: DatetimeModel;
  @observable details: DetailsModel;

  constructor() {
    this.details = new DetailsModel();
    this.doctor = new DoctorModel();
  }
}

export class DetailsModel {
  @observable fullname: string;
  @observable phone: string;
  @observable email: string;
  @observable comment: string;
  @observable appointmentType: AppointmentTypeModel;

  constructor() {
    this.fullname = "";
    this.phone = "";
    this.email = "";
    this.comment = "";
    this.appointmentType = new AppointmentTypeModel();
  }
}

import { ApiResponse } from "../models/response";
import qs from "qs";

export default class Networking {
  API: string;

  constructor(API: string) {
    this.API = API;
  }

  public request<T extends object>(
    method = "GET",
    endPoint: string,
    payload = {}
  ): Promise<ApiResponse<T>> {
    let networking = new Networking(this.API);
    return networking.fetch<T>(method, endPoint, payload);
  }

  private async fetch<T extends object>(
    method = "GET",
    endPoint: string,
    payload = {}
  ): Promise<ApiResponse<T>> {
    let fEndPoint = endPoint;
    if (method === "GET" && Object.keys(payload).length !== 0) {
      let qString = "?" + qs.stringify(payload, { arrayFormat: "repeat" });
      fEndPoint += qString;
    }

    let base = this.API;

    const header = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };

    return await fetch(base + fEndPoint, {
      method: method,
      headers: header,
      body: method !== "GET" ? JSON.stringify(payload) : null,
    })
      .then((response) => {
        return response.text().then((responseBodyAsText) => {
          // console.log(responseBodyAsText);
          // console.log(response);
          try {
            if (response.status == 401) {
              return ApiResponse.error<T>(401);
            } else {
              // special case for empty 200 responses
              if (
                (response.status === 200 ||
                  response.status === 201 ||
                  response.status === 204) &&
                responseBodyAsText == ""
              ) {
                return ApiResponse.success<T>(null, response.status);
              }

              const json = JSON.parse(responseBodyAsText);
              if (response.status === 200 || response.status === 201) {
                return ApiResponse.success<T>(json as T, response.status);
              } else {
                // POST to /stripe/source/ return HTTP 402 (probably straight from Stripe API)
                if (response.status === 400 || response.status === 402) {
                  if (json.error) {
                  } else if (json.message) {
                  }
                }
                return ApiResponse.error<T>(response.status, json);
              }
            }
          } catch (e) {
            return ApiResponse.error<T>();
          }
        });
      })
      .catch((error) => {
        // console.log(error);
        return ApiResponse.error<T>();
      });
  }
}

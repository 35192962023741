import { DatetimeModel } from "./datetimeModel";

export class DoctorModel {
  id: string;
  userId: string;
  specializationId: string;
  description: string;
  userName: string;
  specializations: string;
  photoUrl: string;
  selected: boolean;
  times: Array<DatetimeModel>;

  constructor() {
    this.times = Array<DatetimeModel>();
  }
}

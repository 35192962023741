import React from "react";
import { inject, observer } from "mobx-react";
import { ApplicationStore } from "../../store/applicationStore";
import { Button } from "./buttonComponent";

interface IProps{

}

interface IInjectProps {
  applicationStore: ApplicationStore;
}

@inject("applicationStore")
@observer
export class FooterComponent extends React.Component<IProps>{
  get injected() {
    return (this.props as unknown) as IInjectProps;
  }

  constructor(props: IProps){
    super(props)
  }

  goBack = () => {
    this.injected.applicationStore.stage--;
  }

  goNext = () => {
    this.injected.applicationStore.stage++;
  }

  render(){

    return(
      <div className="footer">
        {this.injected.applicationStore.stage < 5 && (
          <div className="footer__action">
          {this.injected.applicationStore.stage > 1 && (this.injected.applicationStore.stage != 2 || this.injected.applicationStore.branches.length != 1) ? (
            <Button class="btn--action" onClick={this.goBack}>Назад</Button>
          ):(
            <div></div>
          )}
          {this.injected.applicationStore.stage == 4 && (
            // <Button class={this.injected.applicationStore.nextDisabled ? "btn--action is-disabled" : "btn--action"} onClick={this.goNext}>Далее</Button>
            <div className="footer__to-order">
              {this.injected.applicationStore.error != "" && (
                <div className="footer__error">
                  {this.injected.applicationStore.error}
                </div>
              )}
              <Button class={this.injected.applicationStore.nextDisabled ? "btn--action is-disabled" : "btn--action"} onClick={this.injected.applicationStore.makeAppointment}>Записаться</Button>
            </div>
          )}
        </div>
      )}
      {this.injected.applicationStore.termsLink !== "" && (
        <div className="footer__terms">
          Нажимая кнопку «Отправить», я даю свое согласие на обработку моих персональных данных, определенных в <a target="_blank" href={this.injected.applicationStore.termsLink}>Согласии на обработку персональных данных</a>.
        </div>
      )}
    </div>
    )
  }
}
import React from "react";
import classNames from "classnames";

interface IProps{
  onClick?: () => void;
  active?: boolean;
  disabled?: boolean;
  class?: string;
}

export class Button extends React.Component<IProps>{

  constructor(props: IProps){
    super(props)

  }

  render(){

    return(
      <div onClick={this.props.onClick} className={classNames({
        "btn": true,
        "btn--active": this.props.active,
        "is-disabled": this.props.disabled,
        [`${this.props.class}`]: this.props.class
      })}>
        {this.props.children}
      </div>
    )
  }
}
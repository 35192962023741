import React from "react";
import { HeaderComponent } from "../UI/headerComponent";
import { ApplicationStore } from "../../store/applicationStore";
import { inject, observer } from "mobx-react";
import { BranchComponent } from "../branch/branchComponent";
import { Loader } from "../UI/loaderComponent";
import { FooterComponent } from "../UI/footerComponent";
import { DoctorComponent } from "../doctor/doctorComponent";
import { DatetimeComponent } from "../datetime/datetimeComponent";
import { AppointmentComponent } from "../appointment/appointmentComponent";
import { SuccessComponent } from "../Success/successComponent";
import { ServicesComponent } from "../services/servicesComponent";

interface IProps {}

interface IInjectProps {
  applicationStore: ApplicationStore;
}

@inject("applicationStore")
@observer
export class FormComponent extends React.Component<IProps> {
  get injected() {
    return (this.props as unknown) as IInjectProps;
  }

  constructor(props: IProps) {
    super(props);
  }

  render() {
    return (
      <div className="app">
        <HeaderComponent />
        <div className="container">
          <Loader loading={this.injected.applicationStore.loading} />
          {this.injected.applicationStore.stage == 1 && <BranchComponent />}
          {this.injected.applicationStore.stage == 2 && <ServicesComponent />}
          {this.injected.applicationStore.stage == 3 && (
            // <DoctorComponent />
            <DatetimeComponent />
          )}
          {this.injected.applicationStore.stage == 4 && (
            <AppointmentComponent />
          )}
          {this.injected.applicationStore.stage == 5 && <SuccessComponent />}
        </div>
        <FooterComponent />
      </div>
    );
  }
}

import React, { useState } from "react";
import { observer } from "mobx-react";
import { observable } from "mobx";

interface IProps {
  text: string;
}

@observer
export class CroppedText extends React.Component<IProps> {
  @observable full: Boolean;
  text: string;

  constructor(props: IProps) {
    super(props);
    this.full = false;
    this.text = props.text;
  }

  render() {
    return (
      <>
        {this.full ? this.text : this.text.substring(0, 550)}
        {this.text.length > 550 && (
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              this.full = !this.full;
            }}
          >
            {this.full ? "Свернуть" : "Подробнее"}
          </a>
        )}
      </>
    );
  }
}

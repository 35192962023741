import React from "react";
import { inject, observer } from "mobx-react";
import { ApplicationStore } from "../../store/applicationStore";
import Calendar from "react-calendar";
import { observable, toJS, computed, runInAction } from "mobx";
import { Button } from "../UI/buttonComponent";
import moment from "moment";
import { DatetimeModel } from "../../models/datetimeModel";
import { Loader } from "../UI/loaderComponent";
import classNames from "classnames";
import { DoctorModel } from "../../models/doctorModel";
import { CroppedText } from "../UI/croppedText";

interface IProps {}

interface IInjectProps {
  applicationStore: ApplicationStore;
}

@inject("applicationStore")
@observer
export class DatetimeComponent extends React.Component<IProps> {
  get injected() {
    return (this.props as unknown) as IInjectProps;
  }

  @observable date: Date;
  @observable searchTerm: string;
  @observable datetime: Array<DatetimeModel>;

  constructor(props: IProps) {
    super(props);

    this.date = new Date();
    this.searchTerm = "";
    this.datetime = new Array<DatetimeModel>();
    this.injected.applicationStore.initTotalDate();
  }
  componentDidMount() {
    this.getData();
  }

  onChange = (date: any) => {
    this.date = date;
    this.datetime = new Array<DatetimeModel>();
    this.injected.applicationStore.initTotalDate();
    this.getData();
  };

  chooseDate = (item: DatetimeModel) => {
    this.injected.applicationStore.total.datetime = item;
    this.injected.applicationStore.stage = 4;
  };

  getData = async () => {
    this.injected.applicationStore.loadingSwitcher();
    this.injected.applicationStore.getDoctors(false);
    this.injected.applicationStore.getWorkDates(this.date, false);
    this.injected.applicationStore.getDateTime(this.date, false);
    for (let doc of this.injected.applicationStore.doctors) {
      if (doc.selected === true) {
        doc.selected = false;
      }
    }
    this.injected.applicationStore.loadingSwitcher();
  };

  findDay = (day: Date) => {
    for (let date of this.injected.applicationStore.workDates) {
      if (moment(day).isSame(date, "day")) {
        return false;
      }
    }
    return true;
  };

  update = (date: any) => {
    this.injected.applicationStore.getWorkDates(date.activeStartDate);
    this.injected.applicationStore.getDateTime(date.activeStartDate);
  };

  handleDoctorClick = (doctor: DoctorModel) => {
    if (this.injected.applicationStore.total.doctor === doctor) {
      this.injected.applicationStore.total.doctor = new DoctorModel();
      this.datetime = new Array<DatetimeModel>();
      doctor.selected = false;
    } else {
      for (let doc of this.injected.applicationStore.doctors) {
        if (doc.selected === true) {
          doc.selected = false;
        }
      }
      doctor.selected = true;
      this.injected.applicationStore.total.doctor = doctor;
      // this.calculateDatetimes();
    }
  };

  calculateDatetimes = () => {
    this.datetime = new Array<DatetimeModel>();
    for (let date of this.injected.applicationStore.datetimes) {
      if (
        date.doctorSpecializationId ===
          this.injected.applicationStore.total.doctor.id &&
        moment(this.date).isSame(date.date, "day")
      ) {
        this.datetime.push(date);
      }
    }
  };

  onSearch = (searchTerm: string) => {
    this.searchTerm = searchTerm;
  };

  @computed get getDoctors() {
    return (
      <div className="doctors">
        <div className="list-filter">
          <div className="list-filter__input">
            <div className="form">
              <div className="form__row">
                <input
                  className="form__control"
                  type="text"
                  id="search"
                  placeholder="Иванов Иван Петрович"
                  onInput={(e) =>
                    this.onSearch((e.target as HTMLInputElement).value)
                  }
                />
                <label className="form__label" htmlFor="search">
                  Поиск по врачам
                </label>
              </div>
            </div>
          </div>
        </div>
        {this.injected.applicationStore.doctors
          .filter(
            (doc) =>
              doc.times &&
              doc.times.length > 0 &&
              (this.searchTerm !== ""
                ? doc.userName
                    .toLocaleLowerCase()
                    .indexOf(this.searchTerm.toLocaleLowerCase()) >= 0
                : true)
          )
          .map((doctor) => (
            <React.Fragment key={doctor.id}>
              <div
                onClick={() => this.handleDoctorClick(doctor)}
                className={classNames({
                  doctor: true,
                  "doctor--active": doctor.selected,
                })}
              >
                <div className="doctor__photo">
                  {doctor.photoUrl && <img src={doctor.photoUrl} />}
                </div>
                <div className="doctor__specializations">
                  {doctor.specializations}
                </div>
                <div className="doctor__name">{doctor.userName}</div>
                <div className="doctor__description">
                  {doctor.description && (
                    <CroppedText text={doctor.description} />
                  )}
                </div>
              </div>
              {doctor.selected === true && (
                <div className="columns">
                  {doctor.times.map((item, index) => (
                    <Button
                      key={index}
                      class="column-item"
                      active={
                        this.injected.applicationStore.total.datetime == item
                      }
                      onClick={() => this.chooseDate(item)}
                    >
                      {moment(item.timeBegin, "HH:mm:ss").format("HH:mm")} -{" "}
                      {moment(item.timeEnd, "HH:mm:ss").format("HH:mm")}
                    </Button>
                  ))}
                </div>
              )}
            </React.Fragment>
          ))}
      </div>
    );
  }

  render() {
    const trigger = this.injected.applicationStore.workDates;
    return (
      <div className="dateAndDoctor">
        <Calendar
          className="calendar"
          showNeighboringMonth={false}
          onChange={this.onChange}
          onActiveDateChange={this.update}
          value={this.date}
          minDetail="month"
          minDate={moment().toDate()}
          tileDisabled={(props: any) => this.findDay(props.date)}
          // return this.injected.applicationStore.datetimes.filter((date: DatetimeModel) => {
          //   return moment(date.date).isSame(props.date, 'day')
          // }).length == 0
          tileClassName="calendar__tile"
        />
        {this.getDoctors}
      </div>
    );
  }
}

import React from "react";
import { ApplicationStore } from "../../store/applicationStore";
import { inject, observer } from "mobx-react";
import { DoctorModel } from "../../models/doctorModel";
import { Button } from "../UI/buttonComponent";
import { observable, toJS } from "mobx";
import { SpecializationModel } from "../../models/specializationModel";
import { ServiceModel } from "../../models/serviceModel";

interface IProps {}

interface IInjectProps {
  applicationStore: ApplicationStore;
}

@inject("applicationStore")
@observer
export class ServicesComponent extends React.Component<IProps> {
  get injected() {
    return (this.props as unknown) as IInjectProps;
  }
  @observable list: Array<ServiceModel>;

  constructor(props: IProps) {
    super(props);

    this.list = new Array<ServiceModel>();
    this.init();
  }

  init = async () => {
    this.list = await this.injected.applicationStore.getServices();
  };

  handleSelect = (service: ServiceModel) => {
    if (service.childs.length > 0 || service.parentId === null) {
      if (service.parentId === null && service.childs.length === 0) {
        this.injected.applicationStore.total.service = service;
        this.injected.applicationStore.stage = 3;
      }
      service.selected = !service.selected;
    } else {
      this.injected.applicationStore.total.service = service;
      this.injected.applicationStore.stage = 3;
    }
  };

  drawChild = (service: ServiceModel, lvl: number = 1) => {
    if (!service.selected) return;
    let childs = [];
    for (let child of service.childs || []) {
      childs.push(this.drawService(child, lvl));
    }
    return childs;
  };

  drawService = (service: ServiceModel, lvl: number) => {
    return (
      <React.Fragment key={service.id}>
        <Button
          class={`list-item list-item--sub-${lvl}`}
          active={service.selected}
          onClick={() => this.handleSelect(service)}
        >
          {service.name}
        </Button>
        {service.selected && this.drawChild(service, lvl + 1)}
      </React.Fragment>
    );
  };

  onSearch = (searchTerm: string) => {
    if (searchTerm === "") {
      this.list = this.injected.applicationStore.services;
    } else {
      this.list = this.injected.applicationStore.services.filter(
        (service) =>
          service.name
            .toLocaleLowerCase()
            .indexOf(searchTerm.toLocaleLowerCase()) >= 0
      );
      for (let service of this.injected.applicationStore.services) {
        this.getChilds(service, searchTerm.toLocaleLowerCase());
      }
    }
  };

  getChilds = (service: ServiceModel, searchTerm: string) => {
    let childs = service.childs.filter(
      (service) => service.name.toLocaleLowerCase().indexOf(searchTerm) >= 0
    );
    this.list.push(...childs);
    for (let service of childs) {
      this.getChilds(service, searchTerm);
    }
  };

  render() {
    return (
      <React.Fragment>
        <div className="list-filter">
          <div className="list-filter__input">
            <div className="form">
              <div className="form__row">
                <input
                  className="form__control"
                  type="text"
                  id="search"
                  placeholder="Первичный прием"
                  onInput={(e) =>
                    this.onSearch((e.target as HTMLInputElement).value)
                  }
                />
                <label className="form__label" htmlFor="search">
                  Поиск по услугам
                </label>
              </div>
            </div>
          </div>
        </div>
        <div className="list list--expanded">
          {this.list.map((service) => (
            <React.Fragment key={service.id}>
              <Button
                class="list-item"
                active={service.selected}
                onClick={() => this.handleSelect(service)}
              >
                {service.name}
              </Button>
              {this.drawChild(service)}
            </React.Fragment>
          ))}
        </div>
      </React.Fragment>
    );
  }
}
